import React, { useState, useEffect } from 'react'
import Loader from 'components/Loader/Loader'
import DataGrid from 'components/DataTable/DataGridWrapper'
import { AllergiesByProfileIdAPI } from 'services/Services'
import { useSearchField } from 'hoc/SearchFieldProvider'
import filterEntries from 'util/filterEntries'
import AlertDialog from 'components/AlertDialog'
import { IconButton } from '@mui/material'
import { ToolTipIcon } from 'assets/svg/tooltipIcon'
import { getDate, isValidDate } from 'use/Helper'

const Allergies = () => {
    const columns = [
        {
            field: 'providerName',
            headerName: 'Provider name',
        },
        {
            field: 'patient',
            headerName: 'Patient name',
        },
        {
            field: 'substance',
            headerName: 'Substance',
        },
        {
            field: 'type',
            headerName: 'Type',
        },
        {
            field: 'category',
            headerName: 'Category',
        },
        {
            field: 'recordedDate',
            headerName: 'Recorded Date',
            type: 'date',
            flex: 0.5,
            renderCell: (param) => (isValidDate(param.value) ? getDate(param.value) : ''),
        },
        {
            field: 'asserter',
            headerName: 'Asserter',
        },
        {
            field: '',
            headerName: 'Click icon for details',
            flex: 0.2,
            sortable: false,
            disablecolumnmenu: true,
            // Icon clicked to display consolidated information about data row
            renderCell: (param) => (
                <IconButton
                    onClick={() => {
                        setModal(true)
                        setAllergySelected(param.row)
                    }}
                >
                    <ToolTipIcon />
                </IconButton>
            ),
        },
    ]

    const [entries, setEntries] = useState([])
    const [loading, setLoading] = useState(false)
    const [modelOpen, setModal] = useState(false)
    const [allergySelected, setAllergySelected] = useState({})

    const { searchTerm, filterByColumn, setSearchColumns, searchColumns, selectedDependent } = useSearchField()

    const getFilteredEntries = () => {
        const columns = filterByColumn === 'all' ? searchColumns : [filterByColumn]
        return filterEntries(entries, columns, searchTerm)
    }

    const getData = async () => {
        let profileId = selectedDependent?.id || localStorage.getItem('profileid')
        if (!profileId) return
        setLoading(true)
        const data = await AllergiesByProfileIdAPI(profileId)
        if (data) {
            if (data.message === 'No Data Found') {
                setEntries([])
                setLoading(false)
            } else {
                setEntries(data?.allergyIntolence)
                setLoading(false)
            }
        } else {
            setLoading(false)
        }
    }

    useEffect(() => {
        getData()
    }, [selectedDependent])

    useEffect(() => {
        setSearchColumns([
            {
                value: 'providerName',
                label: 'Provider name',
            },
            {
                value: 'substance',
                label: 'Substance',
            },
            {
                value: 'status',
                label: 'Status',
            },
            {
                value: 'category',
                label: 'Category',
            },
            {
                value: 'reaction',
                label: 'Reaction',
            },
        ])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="container-right">
            {loading ? (
                <Loader pageName="vitals" />
            ) : (
                <div className="white-container">
                    <div style={{ height: 500, width: '100%' }}>
                        <DataGrid
                            columns={columns}
                            disableColumnFilter
                            disableColumnSelector
                            rows={getFilteredEntries()}
                            disableSelectionOnClick
                            getRowId={(r) => JSON.stringify(r)}
                        />
                    </div>
                    {Object.keys(allergySelected).length > 0 && (
                        <AlertDialog
                            title="Allergy details"
                            fullWidth
                            open={modelOpen}
                            setOpen={(value) => setModal(value)}
                        >
                            {allergySelected.recorder && (
                                <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                                    <label className="fw-bold">Recorder:</label> {allergySelected.recorder || '-'}
                                </div>
                            )}
                            {allergySelected.code && (
                                <div style={{ marginBottom: '20px' }}>
                                    <label className="fw-bold">Code:</label> {allergySelected.code || '-'}
                                </div>
                            )}
                            {allergySelected.substance && (
                                <div style={{ marginBottom: '20px' }}>
                                    <label className="fw-bold">Manifestation:</label> {allergySelected.substance || '-'}
                                </div>
                            )}
                            {allergySelected.description && (
                                <div style={{ marginBottom: '20px' }}>
                                    <label className="fw-bold">Description:</label> {allergySelected.description || '-'}
                                </div>
                            )}
                            {allergySelected.onset && (
                                <div style={{ marginBottom: '20px' }}>
                                    <label className="fw-bold">Onset:</label> {allergySelected.onset || '-'}
                                </div>
                            )}
                            {allergySelected.severity && (
                                <div style={{ marginBottom: '20px' }}>
                                    <label className="fw-bold">Severity:</label> {allergySelected.severity || '-'}
                                </div>
                            )}
                            {allergySelected.note && (
                                <div style={{ marginBottom: '20px' }}>
                                    <label className="fw-bold">Note:</label> {allergySelected.note || '-'}
                                </div>
                            )}
                            {allergySelected.exposureRoute && (
                                <div style={{ marginBottom: '20px' }}>
                                    <label className="fw-bold">Exposure Route:</label>{' '}
                                    {allergySelected.exposureRoute || '-'}
                                </div>
                            )}
                        </AlertDialog>
                    )}
                </div>
            )}
        </div>
    )
}

export default Allergies
