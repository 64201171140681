import React from 'react'
import DataGrid from 'components/DataTable/DataGridWrapper'
import Loader from 'components/Loader/Loader'
import AlertDialog from 'components/AlertDialog'
import { MedicationStatementByProfileIdAPI } from 'services/Services'
import { useSearchField } from 'hoc/SearchFieldProvider'
import filterEntries from 'util/filterEntries'
import { ToolTipIcon } from 'assets/svg/tooltipIcon'
import { IconButton } from '@mui/material'
import { getDate, isValidDate } from 'use/Helper'

class MedicationsImpl extends React.Component {
    constructor() {
        super()
        this.state = {
            open: false,
            value: null,
            selected: {},
            loading: true,
            // multiSelect: [],
        }
    }

    componentDidMount() {
        const { setSearchColumns, selectedDependent } = this.props

        this.medicationStatementByProfileId(selectedDependent?.id)
        setSearchColumns([
            {
                value: 'providerName',
                label: 'Provider name',
            },
            {
                value: 'dose',
                label: 'Dose',
            },
            {
                value: 'informationSource',
                label: 'Prescribed by',
            },
            {
                value: 'medicationCodeableConcept',
                label: 'Medicine',
            },
            {
                value: 'patient',
                label: 'Patient',
            },
            {
                value: 'datePrescribed',
                type: 'date',
                label: 'Date',
            },
        ])
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedDependent } = this.props
        if (selectedDependent?.id !== prevProps.selectedDependent?.id) {
            this.medicationStatementByProfileId(selectedDependent?.id)
        }
    }

    // Function to retrieve patient medications from Matchrite API
    medicationStatementByProfileId = async (selectedDependentId) => {
        let profileid = selectedDependentId || localStorage.getItem('profileid')
        this.setState({
            loading: true,
        })
        const data = await MedicationStatementByProfileIdAPI(profileid)

        if (data) {
            if (data.message === 'No Data Found') {
                this.setState({ value: [], loading: false })
            } else {
                this.setState({
                    value: data.medicationStatement,
                    loading: false,
                })
            }
        } else {
            this.setState({ loading: false })
        }
    }

    getFilteredEntries = () => {
        const { value } = this.state
        const { searchTerm, searchColumns, filterByColumn } = this.props
        const columns = filterByColumn === 'all' ? searchColumns : [filterByColumn]
        return filterEntries(value, columns, searchTerm)
    }

    // handleSelected(value) {
    //     console.log('value', value)
    // }

    render() {
        if (this.state.loading) {
            return <Loader pageName="medications" />
        }

        // Columns for data grid that will that will display patient's documented medications
        const columns = [
            // {
            //     field: '',
            //     headerName: '',
            //     flex: 0.5,
            //     renderCell: (param) => (
            //         <>
            //             {/* datePrescribed */}
            //             <Checkbox
            //                 checked={param.row.datePrescribed}
            //                 onChange={() => this.handleSelected(param.row)}
            //             />
            //         </>
            //     ),
            // },
            {
                field: 'providerName',
                headerName: 'Provider name',
                flex: 0.5,
                sx: {
                    width: 140,
                },
            },
            {
                field: 'dose',
                headerName: 'Dose',
                flex: 0.5,
            },
            {
                field: 'informationSource',
                headerName: 'Prescribed by',
                flex: 0.5,
                sx: {
                    width: 130,
                },
            },
            {
                field: 'medicationCodeableConcept',
                headerName: 'Medicine',
                flex: 0.7,
            },
            {
                field: 'patient',
                headerName: 'Patient',
                flex: 0.5,
            },
            {
                field: 'datePrescribed',
                headerName: 'Date Prescribed',
                type: 'date',
                flex: 0.5,
                renderCell: (param) => (isValidDate(param.value) ? getDate(param.value) : ''),
            },
            {
                field: '',
                headerName: 'Click icon for details',
                flex: 0.2,
                sortable: false,
                disablecolumnmenu: true,
                renderCell: (param) => (
                    // Icon clicked to display consolidated information about data row
                    <IconButton
                        onClick={() =>
                            this.setState({
                                selected: param.row,
                                open: true,
                            })
                        }
                    >
                        <ToolTipIcon />
                    </IconButton>
                ),
            },
        ]

        return (
            <div className="container-right">
                {/* Data grid that populates cells with patient medication data (limit 5 rows per page)*/}
                <div className="white-container">
                    <div style={{ height: 500, width: '100%' }}>
                        <DataGrid
                            columns={columns}
                            disableColumnFilter
                            disableColumnSelector
                            rows={this.getFilteredEntries()}
                            disableSelectionOnClick
                            getRowId={(r) => JSON.stringify(r)}
                        />
                    </div>

                    {/* Popup that displays information about a medication row when the icon in it's furthest right column is clicked */}
                    {Object.keys(this.state.selected).length > 0 && (
                        <AlertDialog
                            fullWidth
                            title="Medication Details"
                            open={this.state.open}
                            setOpen={(value) => this.setState({ open: value })}
                        >
                            {this.state.selected.providerName && (
                                <div style={{ marginBottom: '20px' }}>
                                    <label className="fw-bold">Provider name: </label>{' '}
                                    {this.state.selected.providerName}
                                </div>
                            )}
                            {this.state.selected.patient && (
                                <div style={{ marginBottom: '20px' }}>
                                    <label className="fw-bold">Patient: </label> {this.state.selected.patient}
                                </div>
                            )}
                            {this.state.selected.medicationCodeableConcept && (
                                <div style={{ marginBottom: '20px' }}>
                                    <label className="fw-bold">Medicine: </label>{' '}
                                    {this.state.selected.medicationCodeableConcept}
                                </div>
                            )}
                            {this.state.selected.informationSource && (
                                <div style={{ marginBottom: '20px' }}>
                                    <label className="fw-bold"> Prescribed by: </label>{' '}
                                    {this.state.selected.informationSource}
                                </div>
                            )}
                            {this.state.selected.dose && (
                                <div style={{ marginBottom: '20px' }}>
                                    <label className="fw-bold"> Dose:</label> {this.state.selected.dose}
                                </div>
                            )}
                            {this.state.selected.route && (
                                <div style={{ marginBottom: '20px' }}>
                                    <label className="fw-bold"> Route:</label> {this.state.selected.route}
                                </div>
                            )}
                            {this.state.selected.frequency && (
                                <div style={{ marginBottom: '20px' }}>
                                    <label className="fw-bold"> Frequency:</label> {this.state.selected.frequency}
                                </div>
                            )}
                            {this.state.selected.quantityUnit && (
                                <div style={{ marginBottom: '20px' }}>
                                    <label className="fw-bold"> Quantity unit:</label>{' '}
                                    {this.state.selected.quantityUnit}
                                </div>
                            )}
                            {this.state.selected.quantityValue && (
                                <div style={{ marginBottom: '20px' }}>
                                    <label className="fw-bold"> Quantity value:</label>{' '}
                                    {this.state.selected.quantityValue}
                                </div>
                            )}
                        </AlertDialog>
                    )}
                </div>
            </div>
        )
    }
}

export function Medications(props) {
    const { searchTerm, searchColumns, filterByColumn, setSearchColumns, selectedDependent } = useSearchField()

    return (
        <MedicationsImpl
            {...props}
            searchTerm={searchTerm}
            searchColumns={searchColumns}
            filterByColumn={filterByColumn}
            setSearchColumns={setSearchColumns}
            selectedDependent={selectedDependent}
        ></MedicationsImpl>
    )
}

export default Medications
